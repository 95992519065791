<template>
  <CCardBody>
    <CDataTable
      :items="transactionsData"
      :fields="fields"
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      pagination
      table-filter
      cleaner
    >
    
    <template #SN="{ index}">
      <td>
        {{index++}}
      </td>
    </template>
    <!-- <template #is_approved="{item}">
      <td v-if="item.is_approved">
        <CBadge :color="getBadge(item.is_approved)" style="padding: 8px">
          Paid
        </CBadge>
      </td>
      <td v-else>
        <CBadge :color="getBadge(item.is_approved)" style="padding: 8px">
          Initiated
        </CBadge>
      </td>
    </template> -->
    
    </CDataTable>
  </CCardBody>
</template>

<script>
import axios from 'axios'
import url from '@/main'

const fields = [
  'SN',
  {key:'funded', label: 'Amount Funded'},
  {key:'withdrawn', label: 'Amount Withdrawn'},
  {key: 'date', _style:'min-width:10%;', label: 'Transaction Date' },
  // {key:'is_approved', label: 'Status'}, 
]

export default {
  data () {
    return {
      fields,
      details: [],
      collapseDuration: 0,
      loan: {},
      transactionsData: []
    }
  },
  filters:{
    formateDate(str){
      var date = new Date(str);
      var day = date.getDate();
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      var dateStr = year+"/"+month+"/"+day;
      return dateStr
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case true: return 'success'
        case false: return 'warning'
      }
    },
    
  },
  beforeCreate(){
    this.$store.commit('startLoading');
    axios.get(`${url}/wallets`)
    .then(res=>{
      this.$store.commit('stopLoading')
      console.log(res)
      this.transactionsData = res.data.data.records
    })
    .catch(err=>{
      if(err.response){
        // console.log(err.response)
        if(err.response.data.message == "Invalid or expired JWT"){
          this.$router.push('/app/login')
        }
      }
      this.$store.commit('stopLoading')
      // alert('Unable to load resources') 
    })
  }
}
</script>